<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-12, .cls-14, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
      }

      .cls-10, .cls-11, .cls-12, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient-2);
      }

      .cls-5 {
        fill: url(#inox-gradient-3);
      }

      .cls-6 {
        fill: url(#inox-gradient-4);
      }

      .cls-7 {
        fill: url(#inox-gradient-5);
      }

      .cls-8 {
        fill: url(#inox-gradient-6);
      }

      .cls-9 {
        fill: url(#inox-gradient-7);
      }

      .cls-10 {
        fill: url(#inox-gradient-8);
      }

      .cls-11 {
        fill: url(#inox-gradient-9);
      }

      .cls-12 {
        fill: url(#inox-gradient-10);
      }

      .cls-14 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient" 
      :x1="doorLeftWidth1 + 59" 
      :y1="doorTopHeight1 + 243" 
      :x2="doorLeftWidth1 + 59" 
      :y2="doorTopHeight1 + 51" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox" 
      :x1="doorLeftWidth1 + 96.27" 
      :y1="doorTopHeight1 + 64.87" 
      :x2="doorLeftWidth1 + 96.27" 
      :y2="doorTopHeight1 + 54.97" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox" 
      :x1="doorLeftWidth1 + 96.27" 
      :y1="doorTopHeight1 + 245.84" 
      :x2="doorLeftWidth1 + 96.27" 
      :y2="doorTopHeight1 + 235.95" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-4" data-name="inox" 
      :x1="doorLeftWidth1 + 96.27" 
      :y1="doorTopHeight1 + 87.5" 
      :x2="doorLeftWidth1 + 96.27" 
      :y2="doorTopHeight1 + 77.6" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-5" data-name="inox" 
      :x1="doorLeftWidth1 + 96.27" 
      :y1="doorTopHeight1 + 110.11" 
      :x2="doorLeftWidth1 + 96.27" 
      :y2="doorTopHeight1 + 100.22" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-6" data-name="inox" 
      :x1="doorLeftWidth1 + 96.27" 
      :y1="doorTopHeight1 + 132.74" 
      :x2="doorLeftWidth1 + 96.27" 
      :y2="doorTopHeight1 + 122.84" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-7" data-name="inox" 
      :x1="doorLeftWidth1 + 96.27" 
      :y1="doorTopHeight1 + 155.36" 
      :x2="doorLeftWidth1 + 96.27" 
      :y2="doorTopHeight1 + 145.46" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-8" data-name="inox" 
      :x1="doorLeftWidth1 + 96.27" 
      :y1="doorTopHeight1 + 177.98" 
      :x2="doorLeftWidth1 + 96.27" 
      :y2="doorTopHeight1 + 168.09" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-9" data-name="inox" 
      :x1="doorLeftWidth1 + 96.27" 
      :y1="doorTopHeight1 + 200.6" 
      :x2="doorLeftWidth1 + 96.27" 
      :y2="doorTopHeight1 + 190.7" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-10" data-name="inox" 
      :x1="doorLeftWidth1 + 96.27" 
      :y1="doorTopHeight1 + 223.23" 
      :x2="doorLeftWidth1 + 96.27" 
      :y2="doorTopHeight1 + 213.33" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="handle-gradient" 
      :x1="doorLeftWidth1 + 11.3" 
      :y1="doorTopHeight1 + 152.41" 
      :x2="doorLeftWidth1 + 16.95" 
      :y2="doorTopHeight1 + 152.41" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient" 
      :x1="doorLeftWidth1 + 58.1" 
      :y1="doorTopHeight1 + 240.9" 
      :x2="doorLeftWidth1 + 58.1" 
      :y2="doorTopHeight1 + 59.92" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V04">
      <!--polyline id="inox" 
                v-if="showInox"
                data-name="inox" 
                class="cls-3" 
                :points="`${doorLeftWidth1 + 75.76} ${doorTopHeight1 + 54.98} ${doorLeftWidth1 + 40.42} ${doorTopHeight1 + 54.98} ${doorLeftWidth1 + 40.42} ${doorTopHeight1 + 245.84} ${doorLeftWidth1 + 75.76} ${doorTopHeight1 + 245.84} ${doorLeftWidth1 + 75.76} ${doorTopHeight1 + 164.56} ${doorLeftWidth1 + 75.76} ${doorTopHeight1 + 54.98}`"/-->
      <rect id="inox"
                :x="doorLeftWidth + 32"
                :y="doorTopHeight + 51"
                width="37"
                height="189"
                class="cls-3"></rect>
      <polyline id="inox-2" 
                v-if="showInox"
                data-name="inox" 
                class="cls-4" 
                :points="`${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 64.88} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 64.88} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 54.98} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 54.98} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 64.88}`"/>
      <polyline id="inox-3"     
                v-if="showInox"
                data-name="inox" 
                class="cls-5" 
                :points="`${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 245.84} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 245.84} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 235.95} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 235.95} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 245.84}`"/>
      <polyline id="inox-4" 
                v-if="showInox"
                data-name="inox" 
                class="cls-6" 
                :points="`${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 87.5} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 87.5} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 77.6} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 77.6} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 87.5}`"/>
      <polyline id="inox-5" 
                v-if="showInox"
                data-name="inox" 
                class="cls-7" 
                :points="`${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 110.11} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 110.11} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 100.22} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 100.22} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 110.11}`"/>
      <polyline id="inox-6" 
                v-if="showInox"
                data-name="inox" 
                class="cls-8" 
                :points="`${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 132.74} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 132.74} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 122.84} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 122.84} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 132.74}`"/>
      <polyline id="inox-7" 
                v-if="showInox"
                data-name="inox" 
                class="cls-9" 
                :points="`${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 155.36} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 155.36} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 145.46} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 145.46} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 155.36}`"/>
      <polyline id="inox-8" 
                v-if="showInox"
                data-name="inox" 
                class="cls-10" 
                :points="`${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 177.98} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 177.98} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 168.09} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 168.09} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 177.98}`"/>
      <polyline id="inox-9" 
                v-if="showInox"
                data-name="inox" 
                class="cls-11" 
                :points="`${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 200.6} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 200.6} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 190.7} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 190.7} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 200.6}`"/>
      <polyline id="inox-10" 
                v-if="showInox"
                data-name="inox" 
                class="cls-12" 
                :points="`${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 223.23} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 223.23} ${doorLeftWidth1 + 101.21} ${doorTopHeight1 + 213.33} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 213.33} ${doorLeftWidth1 + 91.32} ${doorTopHeight1 + 223.23}`"/>
      <!--polyline filter="url(#inset-shadow)" id="glass" 
                class="cls-14" 
                :points="`${doorLeftWidth1 + 45.38} ${doorTopHeight1 + 240.9} ${doorLeftWidth1 + 70.82} ${doorTopHeight1 + 240.9} ${doorLeftWidth1 + 70.82} ${doorTopHeight1 + 59.92} ${doorLeftWidth1 + 45.38} ${doorTopHeight1 + 59.92} ${doorLeftWidth1 + 45.38} ${doorTopHeight1 + 240.9}`"/-->
      <rect id="glass"
            class="cls-14"
            :x="doorLeftWidth + 38.11"
            :y="doorTopHeight1 + 59.18"
            width="24.72"
            height="178.74"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
